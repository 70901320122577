.page {
  margin: 30px 0 0 115px;
  width: 60%;
}

.page-title {
  margin: 50px 0 0 15px;
}

.enclose {
  max-width: 50%;
  display: inline;
  overflow: true;
}

.page-footer {
  width: 100%;
  height: 6rem;
  background-color: white;
}

.nav-bar-div {
  background-color: lightgrey;
}

.full-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

/* h1 values */
.form-header {
  display: inline;
  font-size: 2em;
  margin-top: 0em;
  margin-bottom: 0px;
  margin-left: 10px;
  font-weight: bold;
}

/* h2 values */

.form-table {
  margin: 10px;
}

.bullet-button {
  cursor: pointer;
  background-color: lightgrey;
  width: 100px;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  font-weight: bold;
}

.blank-footer {
  width: 100%;
  height: 6rem;
  background-color: white;
}

@media only screen and (max-width: 850px) {
  .nav-bar-cell {
    margin-right: 10px;
  }

  /* h1 values */
  .form-header {
    font-size: 1.17em;
    margin-left: 10px;
    font-weight: bold;
  }
}
